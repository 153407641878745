<template>
  <aTabs default-active-key="1" type="card" style="margin-top: -10px">
    <aTabPane key="1" tab="Na fila">
      <aTable
        class="travel-table"
        style="margin-top: -20px"
        :columns="[
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
          },
          {
            title: 'Nome ',
            dataIndex: 'name',
            key: 'name',
            scopedSlots: { customRender: 'name' },
          },
          {
            title: 'Empresa',
            dataIndex: 'company',
            key: 'company',
            scopedSlots: { customRender: 'company' },
          },
          {
            title: 'Filial',
            dataIndex: 'company_branch',
            key: 'company_branch',
            scopedSlots: { customRender: 'company_branch' },
          },
          {
            title: 'Valor',
            dataIndex: 'value',
            key: 'value',
          },
          {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
          },
          {
            title: 'Pagamento',
            dataIndex: 'payment_methods',
            key: 'payment_methods',
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            scopedSlots: { customRender: 'status' },
          },
        ]"
        :data-source="selectedInvoices"
        :loading="false"
        :pagination="false"
      >
        <div slot="company" slot-scope="record">
          {{ record.trading_name }}
        </div>
        <div slot="company_branch" slot-scope="record">
          {{ record.name }}
        </div>
      </aTable>

      <a-divider orientation="left"> Dados da nova fatura </a-divider>

      <aAlert
        class="f12 mt-20"
        type="info"
        message="Selecione os campos que deverão estar na fatura a ser gerada."
        banner
      />

      <aForm class="mt-30" @submit.prevent="onSubmitForm" :form="form">
        <aRow :gutter="20">
          <aCol :span="4">
            <HayaSelectField
              label="Tipo"
              fieldKey="type"
              :list="formatLists('type')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaSelectField
              label="Nome"
              fieldKey="name"
              :list="formatLists('name')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="3">
            <HayaSelectField
              label="Status"
              fieldKey="status"
              :list="formatLists('status')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="3">
            <HayaSelectField
              label="Data"
              fieldKey="date"
              :list="formatLists('date', 'raw', 'date')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="4">
            <HayaSelectField
              label="Empresa"
              fieldKey="company_id"
              :list="formatLists('company_id', 'company', 'id')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="4">
            <HayaSelectField
              label="Filial"
              fieldKey="company_branch_id"
              :list="formatLists('company_branch_id', 'company_branch', 'id')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="4">
            <aFormItem class="travel-input-outer">
              <label class="filled" for>Valor (R$)</label>
              <aInput
                class="travel-input"
                placeholder="Insira"
                v-currency
                v-decorator="[
                  `value`,
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <aIcon slot="prefix" type="field-svg" />
              </aInput>
            </aFormItem>
          </aCol>

          <aCol :span="6">
            <HayaSelectField
              label="Fornecedor"
              fieldKey="product_supplier_id"
              :list="
                formatLists('product_supplier_id', 'product_supplier', 'id')
              "
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol :span="6">
            <HayaSelectField
              label="Formas de Pagamento"
              fieldKey="payment_methods"
              :list="formatLists('payment_methods')"
              :required="true"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol
            v-if="
              (form.getFieldValue(`type`) == 'Pagamento' &&
                form.getFieldValue(`payment_methods`) == 'Boleto Bancário') ||
              (form.getFieldValue(`type`) == 'Recebimento' &&
                form.getFieldValue(`payment_methods`) == 'Boleto Bancário') ||
              (form.getFieldValue(`type`) == 'Pagamento' &&
                form.getFieldValue(`payment_methods`) ==
                  'Transferência Bancária/PIX') ||
              (form.getFieldValue(`type`) == 'Recebimento' &&
                form.getFieldValue(`payment_methods`) ==
                  'Transferência Bancária/PIX') ||
              (form.getFieldValue(`type`) == 'Férias' &&
                form.getFieldValue(`payment_methods`) == 'Boleto Bancário') ||
              (form.getFieldValue(`type`) == 'Férias' &&
                form.getFieldValue(`payment_methods`) ==
                  'Transferência Bancária/PIX')
            "
            :span="3"
          >
            <HayaSelectField
              label="Banco"
              fieldKey="bank_id"
              :list="formatLists('bank_id')"
              :required="false"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol
            v-if="
              (form.getFieldValue(`type`) == 'Pagamento' &&
                form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
              (form.getFieldValue(`type`) == 'Recebimento' &&
                form.getFieldValue(`payment_methods`) == 'Cartão de Crédito') ||
              (form.getFieldValue(`type`) == 'Férias' &&
                form.getFieldValue(`payment_methods`) == 'Cartão de Crédito')
            "
            :span="3"
          >
            <HayaSelectField
              label="Cartão de Crédito"
              fieldKey="company_credit_card_id"
              :list="
                selectedInvoices.map((item) => {
                  return {
                    label: item.company_credit_card_id,
                    value: item.company_credit_card_id,
                  };
                })
              "
              :required="false"
              :form="form"
              :allowClear="true"
              :showSearch="true"
            />
          </aCol>

          <aCol class="a-center" :span="24">
            <aAlert
              v-if="
                formatLists('product_supplier_id', 'product_supplier', 'id')
                  .length
              "
              class="f12 a-left"
              type="warning"
              message="Ao mesclar, os eventos/faturas selecionados serão apagados."
              banner
            />

            <aAlert
              v-if="
                formatLists('product_supplier_id', 'product_supplier', 'id')
                  .length === 0
              "
              class="f12 a-left"
              type="error"
              message="Você precisar selecionar o fornecedor em pelo menos uma das faturas selecionadas para continuar. Volte na tela anterior e edite um evento para selecionar."
              banner
            />

            <a-button
              v-if="
                formatLists('product_supplier_id', 'product_supplier', 'id')
                  .length && contractList.length
              "
              class="page-button mt-20"
              type="primary"
              html-type="submit"
              :loading="loadingMergeActions"
              :disabled="loadingMergeActions"
            >
              Mesclar faturas
            </a-button>
          </aCol>
        </aRow>
      </aForm>

      <aModal
        :title="false"
        :footer="false"
        :width="380"
        :visible="loadingMergeActions"
      >
        <div class="cblack" style="min-height: 200px">
          <div v-for="(step, i) in mergeStepsNames" :key="i">
            <div v-if="mergeSteps[step].start">
              <aIcon
                v-if="mergeSteps[step].loading"
                type="loading"
                class="mr-10"
              />
              <aIcon
                v-if="!mergeSteps[step].loading"
                :type="mergeSteps[step].success ? 'check' : 'close'"
                :class="mergeSteps[step].success ? 'cgreen' : 'red'"
                class="mr-10"
              />
              <span v-html="mergeSteps[step].message" />
            </div>
          </div>

          <div
            v-if="
              mergeSteps.createEvent.success &&
              mergeSteps.createInvoice.success &&
              mergeSteps.updateContracts.success &&
              mergeSteps.deleteEvents.success &&
              mergeSteps.deleteInvoices.success
            "
            class="cgreen"
          >
            <aIcon type="check" class="mr-10 cgreen" /> <b>FINALIZADO!</b>
          </div>
        </div>
      </aModal>
    </aTabPane>

    <aTabPane
      key="2"
      force-render
      v-if="contractList.length"
      :disabled="loadingContractList"
    >
      <template #tab>
        Paxes da Fatura
        <aIcon class="ml-5" v-if="loadingContractList" type="loading"
      /></template>
      <InvoicePaxesTable
        :contractList="contractList"
        :loading="loadingContractList"
      />
    </aTabPane>
  </aTabs>
</template>

<script>
import { parse } from "vue-currency-input";

import productSupplierMixins from "@/mixins/product-suppliers/productSupplierMixins.js";
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import InvoicePaxesTable from "@/components/finances/sections/InvoicePaxesTable.vue";
import generateInvoicesCalcsAndRules from "@/components/finances/mixins/generateInvoicesCalcsAndRules";

export default {
  name: "MergeEventsModal",
  components: { HayaSelectField, InvoicePaxesTable },
  props: {
    event: Object,
    selectedInvoices: Array,
  },
  mixins: [productSupplierMixins, generateInvoicesCalcsAndRules],
  data() {
    return {
      form: this.$form.createForm(this),
      loadingMergeActions: false,
      contractList: [],
      loadingContractList: false,
      invoiceIdsArr: [],
      mergeStepsNames: [
        "createInvoice",
        "createEvent",
        "updateContracts",
        "deleteEvents",
        "deleteInvoices",
      ],
      mergeSteps: {
        createInvoice: {
          start: false,
          loading: true,
          success: false,
          id: null,
          message: "Gerando nova fatura...",
        },
        createEvent: {
          start: false,
          loading: true,
          success: false,
          id: null,
          message: "Gerando novo evento...",
        },
        updateContracts: {
          start: false,
          loading: true,
          success: false,
          message: "Atualizando contratos...",
        },
        deleteEvents: {
          start: false,
          loading: true,
          success: false,
          message: "Apagando eventos utilizados para mesclagem...",
        },
        deleteInvoices: {
          start: false,
          loading: true,
          success: false,
          message: "Apagando faturas relacionadas aos eventos...",
        },
      },
    };
  },
  mounted() {
    let totalValue = 0;
    this.selectedInvoices.forEach((item) => {
      if (item.raw.invoice_id != 0)
        this.invoiceIdsArr.push(item.raw.invoice_id);
      totalValue += parseFloat(item.raw.value);
    });

    totalValue = `${totalValue.toFixed(2)}`.replace(".", ",");

    this.form.setFieldsValue({
      value: totalValue,
    });

    if (this.invoiceIdsArr == 0) this.invoiceIdsArr.push("no-reults");

    this.loadingContractList = true;
    this.$http
      .get(
        `/contract-meta/list?page=1&per_page=999&order=desc&order-by=id&info_name=invoice_id&info_value=${this.invoiceIdsArr}`
      )
      .then(({ data }) => {
        this.contractList = data.data.map((item) => {
          return item.contract;
        });

        console.log("aaaa", this.newContractList(this.contractList));
      })
      .finally(() => (this.loadingContractList = false));
  },
  methods: {
    formatLists(label, val, subval) {
      const resultArray = [];
      let lastValue;

      this.selectedInvoices.map((value, index) => {
        if (index === 0 || value[label] !== lastValue) {
          let theLabel = "";

          if (label == "company_id") theLabel = value.company.trading_name;
          if (label == "product_supplier_id")
            theLabel = value.product_supplier.id
              ? `${value.product_supplier.id} - ${value.product_supplier.trading_name}`
              : "";
          if (label == "company_branch_id")
            theLabel = value.company_branch.name;

          if (value[label] != 0)
            resultArray.push({
              label: theLabel != "" ? theLabel : value[label],
              value: value[val] ? value[val][subval] : value[label],
            });
        }
        lastValue = value[label];
      });

      return resultArray;
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        values.user_id = this.$store.state.userData.id;
        values.module_id = 0;
        values.voyage_id = 0;
        values.invoice_id = 0;
        values.module = "finance";
        values.description = "Fatura gerada por mesclagem.";
        values.end_date = "0000-00-00";
        values.attachment = "";
        values.extra_attachment = "";
        values.extra_attachment_2 = "";
        values.other_attachments = "";
        values.color = "";
        values.priority = "Alta";
        values.value = parse(values.value);
        values.modified_by = {
          id: this.$store.state.userData.id,
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        };

        values.pay_day = values.date;
        values.due_date = values.date;

        if (values.status == "Pago" && this.event.raw.payday == "0000-00-00")
          values.payday = new Date();

        console.log(err, values);

        if (!err) this.mergeActions(values);
      });
    },
    async mergeActions(values) {
      const delay = (s) => new Promise((res) => setTimeout(res, s));
      await this.createInvoice(values);
      await delay(1000);
      await this.createEvent(values);
      await delay(1000);
      await this.updateContractsInvoiceData(values);
      await delay(1000);
      await this.deleteEvents();
      await delay(1000);
      await this.deleteInvoices();
      await delay(1000);
      await this.finishMergeActions();
    },
    async createInvoice(values) {
      this.loadingMergeActions = true;
      this.mergeSteps.createInvoice.start = true;

      if (values.value > 0) {
        values.to_pay_value = values.value;
      } else {
        values.incoming_value = values.value;
      }

      values.balance_value = 0;

      try {
        const { data } = await this.$http.post("/invoice/create", values);

        if (data.id) {
          this.mergeSteps.createInvoice.id = data.id;
          this.mergeSteps.createInvoice.loading = false;
          this.mergeSteps.createInvoice.success = true;
          this.mergeSteps.createInvoice.message = `<span class="cgreen"><b>FATURA ID ${data.id} GERADA!</b></span>`;

          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "invoice",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou uma nova fatura ID ${data.id} a partir da mesclagem de faturas.`,
          });
        }
      } catch (e) {
        this.mergeSteps.createInvoice.loading = false;
        this.mergeSteps.createInvoice.success = false;
        this.mergeSteps.createInvoice.message = `Erro ao gerar a fatura: ${e.message}`;
      }
    },
    async createEvent(values) {
      this.mergeSteps.createEvent.start = true;
      try {
        values.invoice_id = this.mergeSteps.createInvoice.id;
        const { data } = await this.$http.post("/event/create", values);

        if (data.id) {
          this.mergeSteps.createEvent.id = data.id;
          this.mergeSteps.createEvent.loading = false;
          this.mergeSteps.createEvent.success = true;
          this.mergeSteps.createEvent.message = `<span class="cgreen"><b>EVENTO ID ${data.id} GERADO!</b></span>`;
        }
      } catch (e) {
        this.mergeSteps.createEvent.loading = false;
        this.mergeSteps.createEvent.success = false;
        this.mergeSteps.createEvent.message = `Erro ao gerar o evento: ${e.message}`;
      }
    },
    async updateContractsInvoiceData(values) {
      this.mergeSteps.updateContracts.start = true;

      for (const contract of this.newContractList(this.contractList)) {
        try {
          await this.updateContractsAsyncOperation(
            contract,
            this.mergeSteps.createInvoice.id,
            this.mergeSteps.createEvent.id,
            values
          );
        } catch (e) {
          console.log(e);
        }
      }
    },
    async updateContractsAsyncOperation(
      contract,
      invoiceId,
      eventId,
      invoiceData
    ) {
      let constractData = {};

      if (contract.product_type == "flight") {
        constractData = {
          id: contract.id,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_id`]:
            invoiceId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_event_id`]:
            eventId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_invoice_status`]:
            invoiceData.status,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_paid_at`]:
            invoiceData.date,
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_to_pay_value`]:
            `${contract.to_pay}`.replace(".", ","),
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_incoming_value`]:
            `${contract.incomings}`.replace(".", ","),
          [`contract_finances_${contract.product_type}_${contract.product_id}_traveller_${contract.traveller_id}_balance_value`]:
            `${contract.incomings - contract.to_pay}`.replace(".", ","),
        };
      } else {
        constractData = {
          id: contract.id,
          [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_id`]:
            invoiceId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_event_id`]:
            eventId,
          [`contract_finances_${contract.product_type}_${contract.product_id}_invoice_status`]:
            invoiceData.status,
          [`contract_finances_${contract.product_type}_${contract.product_id}_paid_at`]:
            invoiceData.date,
        };
      }

      if (invoiceData.status == "Pago" || invoiceData.status == "Recebido") {
        constractData[
          `contract_finances_${contract.product_type}_${contract.product_id}_audit_status`
        ] = "Realizado";

        constractData[
          `contract_finances_${contract.product_type}_${contract.product_id}_audit_user_id`
        ] = this.$store.state.userData.id;
      }

      await this.$http.post(`/contract/update-multi-meta`, constractData);
    },
    async deleteEvents() {
      this.mergeSteps.updateContracts.success = true;
      this.mergeSteps.updateContracts.loading = false;
      this.mergeSteps.updateContracts.message = `<span class="cgreen"><b>CONTRATOS ATUALIZADOS!</b></span>`;
      this.mergeSteps.deleteEvents.start = true;

      for (const event of this.selectedInvoices) {
        if (event.id)
          try {
            await this.$http.post(`/event/delete?id=${event.id}`);
          } catch (e) {
            console.log(e);
          }
      }
    },
    async deleteInvoices() {
      this.mergeSteps.deleteEvents.message = `<span class="cgreen"><b>EVENTOS APAGADOS!</b></span>`;
      this.mergeSteps.deleteEvents.success = true;
      this.mergeSteps.deleteEvents.loading = false;
      this.mergeSteps.deleteInvoices.start = true;

      for (const event of this.selectedInvoices) {
        if (event.raw.invoice_id)
          try {
            await this.$http.post(`/invoice/delete?id=${event.raw.invoice_id}`);
          } catch (e) {
            console.log(e);
          }
      }
    },
    async finishMergeActions() {
      this.mergeSteps.deleteInvoices.message = `<span class="cgreen"><b>FATURAS APAGADAS!</b></span>`;
      this.mergeSteps.deleteInvoices.success = true;
      this.mergeSteps.deleteInvoices.loading = false;

      setTimeout(() => {
        this.loadingMergeActions = false;
        this.$emit("onFinishMergeActions");
      }, 1000);
    },
  },
};
</script>
